import React from 'react';
import styles from './SimulatorLayout.module.css';
import InfoCard from './InfoCard';
import SimulatorForm from './SimulatorForm';

export default function SimulatorLayout() {
  return (
    <main className={styles.container}>
        <div className={styles.sidePanel}></div>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>Simulateur de rendement</h2>
        <div className={styles.content}>
          <div className={styles.columns}>
            <div className={styles.leftColumn}>
              <InfoCard />
            </div>
            <div className={styles.rightColumn}>
              <SimulatorForm />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.sidePanel}></div>
    </main>
  );
}