import React, { Fragment, useState } from 'react'
import { useQuery, gql } from '@apollo/client';
import CompteEpargne from '../../../assets/images/header_compte_epargne.jpg'
import Etape_1 from '../../../assets/images/Etape1-Formulaire-Option-n1.png';
import Etape_2 from '../../../assets/images/Etape2-Signature-Option-n2.png';
import Etape_3 from '../../../assets/images/Etape3-Rencontre-Option-n3.png';
import { Link } from 'react-router-dom';
import { Modal } from 'antd';
import CompteEpargneSimulator from './CompteEpargneSimulator';
const ACCROCHES = gql`
    query {
        allPages (

            where: {
                Nom: "COMPTEEPARGNE"
            }
        ){
            id
            Nom
            Accroches (
                first: 1
                orderBy: "Priorite"
            ){
                id
                Titre
                SousTitre
                Description
                Links {
                    id
                    Nom
                    Lien
                }
            }
        }
    }
`;

const COMPTEEPARGNE = gql`
    query {
        allCategorieCompteEpargnes (
            sortBy: Ordre_ASC
        ) {
        id
        Nom
        CompteEpargne (
            sortBy: Ordre_ASC
        ) {
            id
            Titre
            Contenu
        }
        }
    }
`;

function CompteEpargneArticle () {
    const [currentCollapse, setCollapse] = useState([]);
    const [visible, setVisible] = useState(false)
    const { loading: loadingCompte, error: errorCompte, data: dataCompte } = useQuery(COMPTEEPARGNE);
    const { loading, error, data } = useQuery(ACCROCHES);
    const openPopup = () => {
        setVisible(true);
    } 

    const listStyle = {
        marginLeft: '49px',
        fontSize: '1.3333333333rem',
        fontWeight: '400',
        lineHeight: 1.6,
        listStyle: 'disc'
    }

    const foireAuxQuestions = [
        { 
            id: 1,
            Titre: '',
            SousTitre: 'Quelles sont les conditions pour retirer de l\'argent de mon compte d\'épargne ? ',
            Description: 'Les retraits peuvent être effectués à tout moment, mais certaines conditions s\'appliquent en fonction du type de compte. Les retraits importants peuvent nécessiter un préavis, et des limites de montant s’appliquent selon le compte. Il peut y avoir des pénalités en cas de non-respect du préavis de retrait '
        },
        { 
            id: 2,
            Titre: '',
            SousTitre: 'Y a-t-il des avantages fiscaux liés aux comptes d\'épargne ? ',
            Description: 'Oui, le compte de prévoyance 3e pilier 3a peut offrir des avantages fiscaux. '
        },
        { 
            id: 3,
            Titre: '',
            SousTitre: 'Quelles sont les options de placement disponibles pour augmenter mon épargne ? ',
            Description: 'Les comptes à taux préférentiel proposent des taux d’intérêt plus élevés pour des placements à plus long terme. '
        }
    ]
    

    const handleCancel = e => {
        console.log(e);
        setVisible(false);
    };


    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
    if (loadingCompte) return <p>Loading...</p>;
    if (errorCompte) return <p>Error :(</p>;

        const addCollapse = (id) => {
            if(currentCollapse.includes(id)) {
                setCollapse(currentCollapse.filter((e)=>(e !== id)));
            } else {
                setCollapse([...currentCollapse, id ]);
            }
        }

        return (
            <Fragment>
                <div className="slide-home">
                    <div className="content-slides">
                        <div className="images">
                            <img src={CompteEpargne} alt="Ceanet-compte-epargne" />
                        </div>
                    </div>
                </div>

                {
                    data && data.allPages && data.allPages[0] && data.allPages[0].Accroches ? data.allPages[0].Accroches.map((accroche) => (
                        <div key={accroche.id} className="col-md-12 compte-epargne-info">
                            <div className="container" style={{margin: '22px 0 0 50px'}}>
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="info-contact info-one-column">
                                            <h1>{accroche.Titre}</h1>
                                            <p>{accroche.SousTitre}</p>
                                        </div>
                                    </div>
                                </div>
                                <Link to={"/Contact"} className="compte-epargne-btn">
                                    Ouvrir un compte
                                </Link>
                            </div>
                            {/* 
                            <div className='blocs-container' style={{marginTop: 60}}>
                                <p className='page_lead--lead--text'>{accroche.Description}</p>
                                <Link to={"/Contact"} className="compte-epargne-btn" style={buttonStyle} >
                                    Ouvrir un compte
                                </Link> 
                            </div>
                            */}
                        </div>
                    )): ''
                }
                <section id="main-wrapper" className="col-md-12" style={{paddingBottom: '80px'}}>
                    <div className="compte-epargne-container">
                        <div>
                            <h2 style={{textAlign: 'center', padding: '20px 0'}}>Ouvrir un Compte Épargne, <br></br> c’est simple et sécurisé ! </h2>
                            <div className='bloc-de-transition'>
                                <div>
                                    <img width={70} height={70} src={Etape_1}/>
                                    <span className='step-title'>1. Formulaire</span>
                                    <p>Choisissez le type de compte épargne qui correspond à vos besoins <br></br>et remplissez le formulaire d’ouverture de compte en ligne ou rendez-vous en agence.</p>
                                </div>

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={50} height={50} fill='gray'><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/>
                                </svg>

                                <div>
                                    <img width={70} height={70} src={Etape_2}/>
                                    <span className='step-title'>2. Signature</span>
                                    <p>Une fois les documents validés, le compte est activé et les fonds peuvent être déposés.</p>
                                </div>

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={50} height={50} fill='grey'><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/>
                                </svg>

                                <div>
                                    <img width={70} height={70} src={Etape_3}/>
                                    <span className='step-title'>3. À vos côtés</span>
                                    <p>Rencontrez votre conseiller pour échanger sur vos futurs projets et développer votre épargne.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> 

                <section className="section-offre">
                    <div className="container" style={{maxWidth: '100%', margin: '0 50px'}}>
                        <div className="row">
                            {/*<p>{dataCompte}</p>*/}
                            <div className="slide-mobile">
                            {  
                                dataCompte && dataCompte.allCategorieCompteEpargnes ? dataCompte.allCategorieCompteEpargnes.map((categorie) => (
                                    <article key={categorie.id} style={{width: '20%'}}>
                                    {
                                        categorie && categorie.CompteEpargne ? categorie.CompteEpargne.map((compteEpargne) => (
                                        //<img src={'uploads/' + (offre.Image && offre.Image.filename ? offre.Image.filename : '')} alt={offre.Image && offre.Image.filename ? offre.Image.filename : ''} />
                                        <div className="content">
                                            <span className='offres-title'>{compteEpargne.Titre}</span>
                                            <div className="description-height" dangerouslySetInnerHTML={{ __html: compteEpargne.Contenu }}  />
                                            <Link className="link-decouvrir" to="">En savoir plus</Link>
                                        </div>
                                        )) : ''
                                    }
                                    </article>
                                )) : ''
                            }
                            </div>
                        </div>
                    </div>
                </section>
                               
                <CompteEpargneSimulator />

                <section className="col-md-12" style={{paddingTop: '80px'}}>
                    <div className="blocs-container">
                        <article>                        
                            <h2 style={{paddingBottom: 25}}>Découvrez les avantages du Compte Épargne</h2>
                            <p className='page_lead--lead--text'>Le Compte Épargné est un produit bancaire qui vous permet de déposer de l'argent de manière sécurisée tout en générant des intérêts sur la somme épargnée. Il permet de constituer une réserve d’argent pour des projets futurs ou pour faire face à des imprévus financiers.</p>
                        </article>
                        <article>                            
                            <span className='sub-title'>Votre capital est accessible en tout temps.</span>
                            <p className='page_lead--lead--text'>Vous déterminez le montant et la fréquence de vos versements et les retraits peuvent être effectués à tout moment.</p>
                        </article>
                        <article>
                            <span className='sub-title'>Taux d’intérêt attractif.</span>
                            <p className='page_lead--lead--text'>En possédant un Compte Épargne en complément à votre Compte Privé, vous pouvez générer des intérêts compétitifs sur l’argent que vous désirez épargner.</p>
                        </article>
                        <article>
                            <span className='sub-title'>Une banque fiable et accessible.</span>
                            <p className='page_lead--lead--text'>Bénéficiez d’un service de proximité et d’un accompagnement personnalisé. À chacun de vos projets, votre conseiller vous propose une solution d’épargne.</p>
                        </article>
                        <article>
                            <span className='sub-title'>Sécurité des fonds.</span>
                            <p className='page_lead--lead--text'>La Caisse d’Épargne d’Aubonne garantit la sécurité des dépôts par une gestion prudente. Vos fonds sont protégés par la garantie des dépôts esisuisse et assurés jusqu'à un plafond de CHF 100’000 par client.</p>
                        </article>
                        
                        <span className='sub-title'>Conditions</span>
                        <ul style={listStyle}>
                            <li style={{listStyle: 'disc'}}>Identité : Vous devez fournir une pièce d'identité valide (carte d'identité ou passeport) et être âgé de 18 ans ou plus. </li>
                            <li style={{listStyle: 'disc'}}>Domicile : Vous devez habiter en Suisse. </li>
                            <li style={{listStyle: 'disc'}}>Dépôt minimum : Selon le type de compte choisi, un montant minimum peut être requis à l'ouverture. </li>
                        </ul>
                    </div>
                </section>

                <section className="col-md-12" style={{paddingBottom: '80px'}}>
                    <div className="compte-epargne-container">
                        
                            <h2 style={{textAlign: 'center', padding: '40px 0'}}>Foire aux questions</h2>
                            <div className="collapsed-row">
                                {
                                    foireAuxQuestions && foireAuxQuestions ? foireAuxQuestions.map((question) => (
                                        <div key={question.id}>
                                            <h3 style={{overflow:'hidden'}} onClick={() =>
                                                addCollapse(question.id)
                                            }
                                                className={
                                                    currentCollapse.includes(question.id)
                                                        ? "collapsible active"
                                                        : "collapsible"
                                                }
                                            >
                                                {/* <span style={{float:"left", width:"30% !important"}}>{compte.Titre}</span> */}<div style={{color:"#848484 !important",float:"left",marginBottom:"10px",width:"70% !important"}}> {question.SousTitre}</div> 
                                            </h3>
                                            <div className="content-collapse" style={{
                                                display:
                                                    currentCollapse.includes(question.id)
                                                        ? "block"
                                                        : "none",
                                            }}>
                                                {/*<h4> {compte.Titre}</h4>*/}
                                                <p>
                                                    {question.Description}
                                                </p>
                                                {/* <div className="taux">
                                                    <span>Taux</span>
                                                    <span style={{lineHeight:"32px",maxWidth:"200px"}} className="pourcentage-taux" dangerouslySetInnerHTML={{ __html: compte.Taux}}></span>
                                                </div>
                                                <div className="conditions">
                                                    <div className="titre">Conditions</div>
                                                    <div style={{float:'left'}} dangerouslySetInnerHTML={{__html: compte.Contenu}}></div>
                                                </div> */}
                                                <Link to={"/Contact"} className="btn-link">
                                                    Ouvrir un compte
                                                </Link>
                                            </div>

                                        </div>
                                    )) : ''
                                }
                            </div>
                        
                    </div>
                </section>
            </Fragment>
        );
    }

export default CompteEpargneArticle
