import React from 'react';
import styles from './InfoCard.module.css';

export default function InfoCard() {
  return (
    <div className={styles.card}>
      <span className={styles.heading}>Simuler votre épargne</span>
      <p className={styles.description}>
        La simulation épargne vous donne un aperçu du rendement de vos fonds déposés. 
        Nos conseillers se font un plaisir de vous aider à choisir le compte épargne 
        le mieux adapté à votre projet.
      </p>
      <button 
        className={styles.button}
        onClick={() => {window.location.href = '/Contact'}}
        aria-label="Contacter votre conseiller financier">
        Contacter votre conseiller
      </button>
    </div>
  );
}