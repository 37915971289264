import React from 'react'
import InformationImportante from './InformationImportante';
import CEA_TAUXCONDITIONS from '../../assets/pdf/CEA_TAUX_CONDITIONS_2025.pdf';

import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';

const ASIDES = gql`
    query {
        allPages (
            first: 2
            where: {
                Nom: "COMPTE"
                Asides_every: {
                    Page_every: {
                        Nom: "COMPTE"
                    }
                }
            }

        ){
            id
            Nom
            Asides (
                orderBy: "Priorite" 
            ){
                id
                Titre
                SousTitre
                Priorite
                Description
                Link {
                id
                Nom
                Lien
                }
            }
        }
    }
`;


function AsideContentInfo({ openPopup }) {
    const { loading, error, data } = useQuery(ASIDES);
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <div className="side-right">
            {
                data && data.allPages && data.allPages[0] && data.allPages[0].Asides ? data.allPages[0].Asides.map((aside) => (
                    <div key={aside.id}>
                        <h2>{aside.Titre}</h2>
                        <div className="actualites">
                            <div className="right-comptes">
                                <h3>{aside.SousTitre}</h3>
                                <p>{aside.Description}</p>
                                {aside.Link.Lien == "#" ?
                                    <a onClick={openPopup}>{aside.Link ? aside.Link.Nom : ''}</a>
                                    :
                                    <Link to={aside.Link ? aside.Link.Lien : ''}>{aside.Link ? aside.Link.Nom : ''}</Link>

                                }
                            </div>
                        </div>
                    </div>
                )) : ''
            }
            <h2><a className='taux-condition' href={CEA_TAUXCONDITIONS} target="_blank">Taux et conditions</a></h2>
            <InformationImportante />
        </div>
    )
}

export default AsideContentInfo
