import React, { useEffect, useState } from 'react';
import styles from './SimulatorForm.module.css';

export default function SimulatorForm() {
    const [ capital, setCapital ] = useState(10000);
    const [ percentage, setPercentage ] = useState(1) 
    const [ amount, setAmount ] = useState(1000);  
    const [ duration, setDuration ] = useState(1); 
    const [ interest, setInterest ] = useState(0);
     
    const [result, setResult] = useState(null);  

    useEffect(() => {
        calculateFutureValue();
    },[capital, percentage, amount, duration]);

    // Function to calculate the future value  
    const calculateFutureValue = () => {  
        // Convert interest rate to a decimal and calculate  
        const r = percentage / 100; // input as percentage  
        const n = duration;  

        // Future Value Calculation  
        const futureValue = capital * Math.pow(1 + r, n) +  
        amount * ((Math.pow(1 + r, n) - 1) / r) * (1 + r); 

        const currentInterest = futureValue - capital - amount;
        setInterest(currentInterest);

        // Set the result to state  
        setResult(futureValue);  
    };

    const formatCurrency = (number) => {  
        return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ');  
    };


  return (
    <>
        <form className={styles.form} onSubmit={(e) => e.preventDefault()}>
        <div className={styles.inputGroup}>
            <label htmlFor="capital" className={styles.label}>Montant initial</label>
            <div className={styles.value} aria-live="polite">
            {formatCurrency(capital)}
            </div>
        </div>
        
        <div className={styles.rangeContainer}>
            <input
            type="range"
            id="capital"
            name="capital"
            className={styles.range}
            min="1000"
            max="100000"
            step="1000"
            value={capital}
            onChange={(e) => setCapital(e.target.value)}
            aria-label="Ajuster le capital de départ"
            />
        </div>

        <div className={styles.inputGroup}>
            <label htmlFor="capital" className={styles.label}>Taux compte épargne plus</label>
            <div className={styles.value} aria-live="polite">
            {percentage}%
            </div>
        </div>  
        <div className={styles.rangeContainer}>
            <input   
                type="range"   
                id="percentage" 
                name="percentage"
                className={styles.range} 
                min="1"   
                max="100"   
                value={percentage}  
                onChange={(e) => setPercentage(e.target.value)}   
            /> 
        </div>
             
        <div className={styles.inputGroup}>
            <label htmlFor="amount" className={styles.label}>Versement annuel</label>
            <div className={styles.value} aria-live="polite">
            {formatCurrency(amount)}
            </div>
        </div>

        <div className={styles.rangeContainer}>
            <input
            type="range"
            id="amount"
            name="amount"
            className={styles.range}
            min="100"
            max="10000"
            step="100"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            aria-label="Ajuster le montant d'épargne"
            />
        </div>

        <div className={styles.inputGroup}>
            <label htmlFor="duration" className={styles.label}>Années</label>
            <div className={styles.value} aria-live="polite">
            {duration} ans
            </div>
        </div>

        <div className={styles.rangeContainer}>
            <input
            type="range"
            id="duration"
            name="duration"
            className={styles.range}
            min="1"
            max="40"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            aria-label="Ajuster la durée en années"
            />
        </div>
        </form>
        <div className={styles.button}>Résultat</div>
        <div className={styles.card}>
            <div className={styles.result}>
                <h2 className={styles.heading}>Montant à l’échéance</h2>
                <h2 className={styles.heading}>{result ? formatCurrency(result):"N/A"} <span style={{color: 'yellow'}}>CHF</span></h2>
            </div>
            <div className={styles.result}>
                <h2 className={styles.heading}>Montant de l’intérêt</h2>
                <h2 className={styles.heading}>{interest ? formatCurrency(interest): "N/A"} <span style={{color: 'yellow'}}>CHF</span></h2>
            </div>
            
            
        </div>
    </>
    
  );
}